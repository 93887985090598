import { Grid, Typography, Link } from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";

export default function Footer() {
  return (
    <Grid item xs={12} sx={{ textAlign: "center", mt: { xs: 4, md: 6 } }}>
      <Grid container rowSpacing={1}>
        <Grid item xs={12}>
          <img src={"logo.png"} width={"80px"} alt="footer" />
        </Grid>
        <Grid item xs={12} sx={{ display: "none" }}>
          <LinkedInIcon sx={{ mx: 2 }} />
          <TwitterIcon sx={{ mx: 2 }} />
          <YouTubeIcon sx={{ mx: 2 }} />
        </Grid>
        <Grid item xs={12} pb={1}>
          <Typography variant="body2">
            {"Copyright © "}
            <Link color="inherit" href="/">
              Eco Farms
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
