import {
  AppBar,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  createTheme,
  CssBaseline,
  Dialog,
  Grid,
  IconButton,
  Skeleton,
  Slide,
  ThemeProvider,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import DropDown from "../components/DropDown";
import Footer from "../components/Footer";
import SideDrawer from "../components/SideDrawer";
import NavBar from "../components/NavBar";
import Product from "../data/Product";
import axios from "axios";
import { TransitionProps } from "@mui/material/transitions";
import { useSearchParams } from "react-router-dom";

const globalTheme = createTheme({
  palette: {
    primary: {
      light: "#ffffff",
      main: "#0c0a0b",
      dark: "#0c0a0b",
      contrastText: "#ffffff",
    },
    secondary: {
      light: "#0c0a0b",
      main: "#0c0a0b",
      dark: "#0c0a0b",
      contrastText: "#000",
    },
  },
  typography: {
    fontFamily: [
      "Google Sans",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});

function removeLoader() {
  setTimeout(function () {
    document.getElementById("loader")?.classList.add("fade-out");
  }, 2000);
  setTimeout(function () {
    document.getElementById("loader")?.remove();
  }, 2500);
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Products() {
  const [searchParams, setSearchParams] = useSearchParams();

  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [openProducts, setOpenProducts] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [products, setProducts] = React.useState<Product[]>([]);

  const [showProductDialog, setShowProductDialog] = React.useState(false);
  const [selectedProduct, setSelectedProduct] = React.useState<Product>();

  const handleProductsClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpenProducts((previousOpen) => !previousOpen);
  };

  useEffect(() => {
    axios
      .post("/eco-farms-data-ingest", {
        action: "GET_PRODUCTS",
      })
      .then((response) => {
        if (response.status === 200) {
          var list = [];
          for (let i = 0; i < response.data.length; i++) {
            let data = response.data[i];
            var entry: Product = {
              name: data[0],
              category: data[1],
              subCategory: data[2],
              description: data[3],
              image: data[4],
              video: data[5],
            };

            list.push(entry);
          }

          setProducts(list);
          removeLoader();
        }
      });
  }, []);

  return (
    <ThemeProvider theme={globalTheme}>
      <CssBaseline />
      <SideDrawer openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} />
      <NavBar
        handleProductsClick={handleProductsClick}
        setOpenDrawer={setOpenDrawer}
        openDrawer={openDrawer}
      />
      <Grid
        container
        spacing={2}
        sx={{ px: { xs: 4, md: 6, lg: 8 }, pt: 4, minHeight: "80vh" }}
      >
        <Grid item xs={12} sx={{ py: 3 }}>
          <Typography variant="h4" fontWeight="bold">
            Products
          </Typography>
        </Grid>

        {products.length === 0 ? (
          <Grid container spacing={3} sx={{ pt: 3 }}>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
              <Skeleton height={600} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
              <Skeleton height={600} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
              <Skeleton height={600} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
              <Skeleton height={600} />
            </Grid>
          </Grid>
        ) : null}

        {products.map((data) => {
          if (searchParams.get("category")) {
            if (data.category !== searchParams.get("category")) {
              return null;
            }
          }

          return (
            <Grid item xs={12} md={6} lg={4}>
              <Card
                sx={{ minHeight: "550px" }}
                onClick={() => {
                  setShowProductDialog(true);
                  setSelectedProduct(data);
                }}
              >
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="280"
                    image={data.image}
                    alt={data.name}
                  />
                  <CardContent sx={{ whiteSpace: "normal" }}>
                    <Typography gutterBottom variant="h5" component="div">
                      {data.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {data.description
                        .split("|")
                        .slice(0, 5)
                        .map((points) => {
                          return <li>{points}</li>;
                        })}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          );
        })}
      </Grid>
      <Grid>
        <Footer />
      </Grid>
      <DropDown
        open={openProducts}
        anchorEl={anchorEl}
        setOpenProducts={setOpenProducts}
        setOpenDrawer={setOpenDrawer}
      />
      <Dialog
        fullScreen
        open={showProductDialog}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <Typography sx={{ flex: 1 }} variant="h6" component="div">
              {selectedProduct?.name}
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              aria-label="close"
              onClick={() => {
                setShowProductDialog(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Grid container>
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <img
              alt=""
              src={selectedProduct?.image}
              style={{ maxWidth: "100vw", maxHeight: "50vh" }}
            />
          </Grid>
          <Grid item xs={12} sx={{ p: { xs: 2, lg: 3 } }}>
            <Typography variant="h4" fontWeight={500}>
              Features
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ p: { xs: 2, lg: 3 } }}>
            {selectedProduct?.description.split("|").map((points) => {
              return <li>{points}</li>;
            })}
          </Grid>
          {selectedProduct?.video !== "NONE" ? (
            <Grid item xs={12} sx={{ p: { xs: 2, lg: 3 } }}>
              <Typography variant="h4" fontWeight={500}>
                Videos
              </Typography>
            </Grid>
          ) : null}
          {selectedProduct?.video.split("|").map((video) => {
            if (video === "NONE") {
              return null;
            } else {
              return (
                <Grid item xs={12} md={6} lg={3} sx={{ p: { xs: 2, lg: 3 } }}>
                  <iframe
                    width="100%"
                    height="315"
                    src={video}
                    title={selectedProduct?.name}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  ></iframe>
                </Grid>
              );
            }
          })}
        </Grid>
      </Dialog>
    </ThemeProvider>
  );
}
