import {
  Button,
  Card,
  CardContent,
  CardMedia,
  CardActionArea,
  createTheme,
  CssBaseline,
  Divider,
  Grid,
  TextField,
  ThemeProvider,
  Typography,
  Box,
  Skeleton,
  Dialog,
  AppBar,
  IconButton,
  Toolbar,
  Slide,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect } from "react";
import Carousel from "react-material-ui-carousel";
import axios from "axios";
import DropDown from "../components/DropDown";
import Footer from "../components/Footer";
import SideDrawer from "../components/SideDrawer";
import NavBar from "../components/NavBar";
import { BrowserView, MobileView } from "react-device-detect";
import Product from "../data/Product";
import { useNavigate } from "react-router-dom";
import { TransitionProps } from "@mui/material/transitions";
import Content from "../data/Content";

const globalTheme = createTheme({
  palette: {
    primary: {
      light: "#ffffff",
      main: "#0c0a0b",
      dark: "#0c0a0b",
      contrastText: "#ffffff",
    },
    secondary: {
      light: "#0c0a0b",
      main: "#0c0a0b",
      dark: "#0c0a0b",
      contrastText: "#000",
    },
  },
  typography: {
    fontFamily: [
      "Google Sans",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});

function removeLoader() {
  setTimeout(function () {
    document.getElementById("loader")?.classList.add("fade-out");
  }, 2000);
  setTimeout(function () {
    document.getElementById("loader")?.remove();
  }, 2500);
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Home() {
  const navigate = useNavigate();

  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [openProducts, setOpenProducts] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [sendContact, setSendContact] = React.useState(false);
  const [sendButton, setSendButton] = React.useState("Submit");
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [message, setMessage] = React.useState("");

  const [content, setContent] = React.useState<Content[]>([]);
  const [products, setProducts] = React.useState<Product[]>([]);

  const [showProductDialog, setShowProductDialog] = React.useState(false);
  const [selectedProduct, setSelectedProduct] = React.useState<Product>();

  const handleProductsClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpenProducts((previousOpen) => !previousOpen);
  };

  const sendContactRequest = () => {
    setSendContact(true);
    axios
      .post(
        "https://us-central1-habiot.cloudfunctions.net/eco-farms-data-ingest",
        { action: "POST_MESSAGE", name: name, email: email, message: message }
      )
      .then((response) => {
        if (response.status === 200) {
          setSendButton("Success!");
        }
        setSendContact(false);
      });
  };

  useEffect(() => {
    axios
      .post("/eco-farms-data-ingest", {
        action: "GET_PRODUCTS",
      })
      .then((response) => {
        if (response.status === 200) {
          var list = [];
          for (let i = 0; i < response.data.length; i++) {
            let data = response.data[i];
            var entry: Product = {
              name: data[0],
              category: data[1],
              subCategory: data[2],
              description: data[3],
              image: data[4],
              video: data[5],
            };

            list.push(entry);
          }

          setProducts(list);
          removeLoader();
        }
      });
  }, []);

  useEffect(() => {
    axios
      .post("/eco-farms-data-ingest", {
        action: "GET_ABOUT_US",
      })
      .then((response) => {
        if (response.status === 200) {
          var list = [];
          for (let i = 0; i < response.data.length; i++) {
            let data = response.data[i];
            var entry: Content = {
              content: data[0],
            };

            list.push(entry);
          }

          setContent(list);
          removeLoader();
        }
      });
  }, []);

  return (
    <ThemeProvider theme={globalTheme}>
      <CssBaseline />
      <SideDrawer openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} />
      <NavBar
        handleProductsClick={handleProductsClick}
        setOpenDrawer={setOpenDrawer}
        openDrawer={openDrawer}
      />
      <Grid container>
        {/* CAROUSEL SECTION */}
        <Grid item xs={12}>
          <BrowserView>
            <Carousel
              animation="fade"
              indicators={false}
              autoPlay={true}
              sx={{ height: "calc(100vh - 61px)" }}
            >
              <img
                alt=""
                className="carousel-image"
                src="/images/carousel/landscape/carousel-1.jpeg"
                style={{ objectPosition: "50% 100%" }}
              />
              <img
                alt=""
                className="carousel-image"
                src="/images/carousel/landscape/carousel-2.jpeg"
                style={{ objectPosition: "50% 100%" }}
              />
              <img
                alt=""
                className="carousel-image"
                src="/images/carousel/landscape/carousel-3.jpeg"
                style={{ objectPosition: "50% 100%" }}
              />
            </Carousel>
          </BrowserView>
          <MobileView>
            <Carousel
              animation="fade"
              indicators={false}
              autoPlay={true}
              sx={{ height: "calc(100vh - 61px)" }}
            >
              <img
                alt=""
                className="carousel-image"
                src="/images/carousel/portrait/carousel-1.jpeg"
                style={{ objectPosition: "50% 100%" }}
              />
              <img
                alt=""
                className="carousel-image"
                src="/images/carousel/portrait/carousel-2.jpeg"
                style={{ objectPosition: "50% 100%" }}
              />
              <img
                alt=""
                className="carousel-image"
                src="/images/carousel/portrait/carousel-3.jpeg"
                style={{ objectPosition: "50% 100%" }}
              />
            </Carousel>
          </MobileView>

          <BrowserView>
            <Typography
              variant="h4"
              fontWeight="bold"
              sx={{
                bottom: "2rem",
                left: { xs: "2rem", md: "3rem", lg: "4rem" },
                position: "absolute",
                zIndex: "1",
                color: "white",
              }}
            >
              Profitable Farming
            </Typography>
          </BrowserView>
          <MobileView>
            <Typography
              variant="h5"
              fontWeight="bold"
              sx={{
                bottom: "2rem",
                left: { xs: "2rem", md: "3rem", lg: "4rem" },
                position: "absolute",
                zIndex: "1",
                color: "white",
              }}
            >
              Profitable Farming
            </Typography>
          </MobileView>
        </Grid>
        <Grid item xs={12} sx={{ px: { xs: 4, md: 6, lg: 8 }, pt: 4 }}>
          <Typography fontSize="1.2rem">{content[0]?.content}</Typography>
        </Grid>

        <Grid item xs={12} sx={{ px: { xs: 4, md: 6, lg: 8 }, pb: 4 }}>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={6}>
                <Typography variant="h4" fontWeight="bold" sx={{ py: 6 }}>
                  Products
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sx={{ alignSelf: "center", textAlign: "right" }}
              >
                <Button href="/products" variant="outlined" size="small">
                  More
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <BrowserView>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <Box
                sx={{
                  overflowX: "scroll",
                  overflowY: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                {products.length === 0 ? (
                  <Grid container spacing={3} sx={{ p: 3 }}>
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                      <Skeleton height={500} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                      <Skeleton height={500} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                      <Skeleton height={500} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                      <Skeleton height={500} />
                    </Grid>
                  </Grid>
                ) : null}

                {products.map((data) => {
                  return (
                    <Card
                      sx={{
                        height: 440,
                        width: 300,
                        display: "inline-block",
                        mr: 3,
                      }}
                      onClick={() => {
                        setShowProductDialog(true);
                        setSelectedProduct(data);
                      }}
                    >
                      <CardActionArea>
                        <CardMedia
                          component="img"
                          height="180"
                          image={data.image}
                          alt={data.name}
                        />
                        <CardContent
                          sx={{ whiteSpace: "normal", textAlign: "left" }}
                        >
                          <Typography gutterBottom variant="h5" component="div">
                            {data.name}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            {data.description
                              .split("|")
                              .slice(0, 5)
                              .map((points) => {
                                return <li>{points}</li>;
                              })}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  );
                })}
              </Box>
            </Grid>
          </BrowserView>
          <MobileView>
            <Grid item xs={12}>
              <Typography>
                We push the limits of innovation to create systems with higher
                efficiency, economic & profit capabilities.
              </Typography>
            </Grid>
          </MobileView>
        </Grid>

        {/* ABOUT US SECTION */}
        <Grid
          id="about-us"
          item
          md={12}
          lg={6}
          sx={{ alignSelf: "center", px: { xs: 4, md: 6, lg: 8 }, py: 4 }}
        >
          <Typography variant="h4" fontWeight="bold" sx={{ py: 4 }}>
            About Us
          </Typography>
          <Typography fontSize="1.2rem">{content[1]?.content}</Typography>
          <Button href="/about" variant="outlined" sx={{ mt: 4 }}>
            More
          </Button>
        </Grid>
        <Grid
          item
          md={12}
          lg={6}
          sx={{
            textAlign: "center",
            py: 4,
            alignSelf: "center",
            display: { xs: "none", lg: "block" },
          }}
        >
          <img
            alt=""
            style={{ width: "-webkit-fill-available" }}
            src="/images/about/about-us.jpeg"
          />
        </Grid>

        {/* CONTACT US SECTION */}
        <Grid
          item
          xs={12}
          sx={{ py: { xs: 2, md: 4, lg: 6 }, px: { xs: 6, md: 12, lg: 18 } }}
        >
          <Divider />
        </Grid>
        <Grid
          item
          md={12}
          lg={6}
          sx={{ alignSelf: "center", px: { xs: 4, md: 6, lg: 8 }, py: 4 }}
        >
          <Grid container>
            <Grid item xs={12}>
              <Typography
                id="contact-us"
                variant="h4"
                fontWeight="bold"
                sx={{ py: 4 }}
              >
                Contact us
              </Typography>
            </Grid>
            <Grid item xs={12} pb={2}>
              <Typography fontSize="1.2rem">
                Thank you for your interest in Eco Farms. Please see below for
                the best ways to contact our team.
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} py={2} sx={{ pr: { md: 2 } }}>
              <TextField
                label="Full Name"
                variant="outlined"
                fullWidth
                onChange={(event) => setName(event.target.value as string)}
              />
            </Grid>
            <Grid item xs={12} md={6} py={2} sx={{ pl: { md: 2 } }}>
              <TextField
                label="E-mail"
                variant="outlined"
                fullWidth
                onChange={(event) => setEmail(event.target.value as string)}
              />
            </Grid>
            <Grid item xs={12} py={2}>
              <TextField
                label="Message"
                variant="outlined"
                fullWidth
                multiline
                rows={3}
                onChange={(event) => setMessage(event.target.value as string)}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3} py={2}>
              <LoadingButton
                loading={sendContact}
                loadingIndicator="Sending..."
                variant="contained"
                size="large"
                fullWidth
                onClick={() => {
                  if (name === "") {
                    window.alert("Name is required!");
                  } else if (email === "") {
                    window.alert("Email is required!");
                  } else if (email === "") {
                    window.alert("Email is required!");
                  } else if (sendButton === "Submit") {
                    sendContactRequest();
                  }
                }}
              >
                {sendButton}
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          lg={6}
          sx={{
            textAlign: "center",
            py: 4,
            alignSelf: "center",
            display: { xs: "none", lg: "block" },
          }}
        >
          <img
            alt=""
            style={{ width: "-webkit-fill-available" }}
            src="/images/contact/contact-us.jpeg"
          />
        </Grid>

        <Footer />
      </Grid>
      <DropDown
        open={openProducts}
        anchorEl={anchorEl}
        setOpenProducts={setOpenProducts}
        setOpenDrawer={setOpenDrawer}
      />
      <Dialog
        fullScreen
        open={showProductDialog}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <Typography sx={{ flex: 1 }} variant="h6" component="div">
              {selectedProduct?.name}
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              aria-label="close"
              onClick={() => {
                setShowProductDialog(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Grid container>
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <img
              alt=""
              src={selectedProduct?.image}
              style={{ maxWidth: "100vw", maxHeight: "50vh" }}
            />
          </Grid>
          <Grid item xs={12} sx={{ p: { xs: 2, lg: 3 } }}>
            <Typography variant="h4" fontWeight={500}>
              Features
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ p: { xs: 2, lg: 3 } }}>
            {selectedProduct?.description.split("|").map((points) => {
              return <li>{points}</li>;
            })}
          </Grid>
          {selectedProduct?.video !== "NONE" ? (
            <Grid item xs={12} sx={{ p: { xs: 2, lg: 3 } }}>
              <Typography variant="h4" fontWeight={500}>
                Videos
              </Typography>
            </Grid>
          ) : null}
          {selectedProduct?.video.split("|").map((video) => {
            if (video === "NONE") {
              return null;
            } else {
              return (
                <Grid item xs={12} md={6} lg={3} sx={{ p: { xs: 2, lg: 3 } }}>
                  <iframe
                    width="100%"
                    height="315"
                    src={video}
                    title={selectedProduct?.name}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  ></iframe>
                </Grid>
              );
            }
          })}
        </Grid>
      </Dialog>
    </ThemeProvider>
  );
}
