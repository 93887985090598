import { TreeView, TreeItem } from "@mui/lab";
import { Fade, Paper, Popper } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useNavigate } from "react-router";
import { VirtualElement } from "@popperjs/core";

export default function DropDown(props: {
  open: boolean;
  anchorEl: VirtualElement | (() => VirtualElement) | null | undefined;
  setOpenProducts: (arg0: boolean) => void;
  setOpenDrawer: (arg0: boolean) => void;
}) {
  const navigate = useNavigate();

  return (
    <Popper
      open={props.open}
      anchorEl={props.anchorEl}
      transition
      sx={{ zIndex: 1 }}
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Paper
            sx={{
              border: 1,
              p: 2,
              pr: 4,
              mt: 1,
              bgcolor: "background.paper",
            }}
          >
            <TreeView
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
              sx={{ width: "280px", p: 1 }}
            >
              <TreeItem nodeId="1" label="Irrigation" sx={{ py: 1 }}>
                <TreeItem
                  nodeId="11"
                  label="Fertimix"
                  sx={{ py: 1, pt: 2 }}
                  onClick={() => {
                    props.setOpenProducts(false);
                    props.setOpenDrawer(false);
                    navigate(
                      "/products?category=" + encodeURIComponent("Irrigation")
                    );
                  }}
                />
                <TreeItem
                  nodeId="12"
                  label="Nutrient maker"
                  sx={{ py: 1 }}
                  onClick={() => {
                    props.setOpenProducts(false);
                    props.setOpenDrawer(false);
                    navigate(
                      "/products?category=" + encodeURIComponent("Irrigation")
                    );
                  }}
                />
              </TreeItem>
              <TreeItem
                nodeId="2"
                label="Environmental Control"
                sx={{ py: 1 }}
                onClick={() => {
                  props.setOpenProducts(false);
                  props.setOpenDrawer(false);
                  navigate("/products");
                }}
              />
              <TreeItem nodeId="3" label="Power Management" sx={{ py: 1 }}>
                <TreeItem
                  nodeId="31"
                  label="Solar Powered Refrigeration"
                  sx={{ py: 1 }}
                  onClick={() => {
                    props.setOpenProducts(false);
                    props.setOpenDrawer(false);
                    navigate(
                      "/products?category=" +
                        encodeURIComponent("Power Management")
                    );
                  }}
                ></TreeItem>
              </TreeItem>
              <TreeItem
                nodeId="4"
                label="Crop Spraying"
                sx={{ py: 1 }}
                onClick={() => {
                  props.setOpenProducts(false);
                  props.setOpenDrawer(false);
                  navigate(
                    "/products?category=" + encodeURIComponent("Crop Spraying")
                  );
                }}
              ></TreeItem>
              <TreeItem
                nodeId="5"
                label="Crop Inspection"
                sx={{ py: 1 }}
                onClick={() => {
                  props.setOpenProducts(false);
                  props.setOpenDrawer(false);
                  navigate(
                    "/products?category=" +
                      encodeURIComponent("Crop Inspection")
                  );
                }}
              ></TreeItem>
            </TreeView>
          </Paper>
        </Fade>
      )}
    </Popper>
  );
}
