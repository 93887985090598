import { TreeView, TreeItem } from "@mui/lab";
import { Drawer, Grid } from "@mui/material";
import { useNavigate } from "react-router";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

export default function SideDrawer(props: {
  openDrawer: boolean | undefined;
  setOpenDrawer: (arg0: boolean) => void;
}) {
  const navigate = useNavigate();

  return (
    <Drawer
      anchor="right"
      open={props.openDrawer}
      onClose={() => props.setOpenDrawer(false)}
    >
      <Grid>
        <Grid item xs={12}>
          <img
            style={{
              paddingTop: "2rem",
              paddingBottom: "2rem",
              paddingLeft: "1rem",
              width: "-webkit-fill-available",
              maxWidth: "100px",
              minWidth: "80px",
            }}
            src={"logo.png"}
            alt="Eco Farms Logo"
          />
        </Grid>
        <Grid item xs={12}>
          <TreeView
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            sx={{ width: "280px", p: 1 }}
          >
            <TreeItem nodeId="products" label="Products" sx={{ py: 1 }}>
              <TreeItem nodeId="1" label="Irrigation" sx={{ py: 1 }}>
                <TreeItem
                  nodeId="11"
                  label="Fertimix"
                  sx={{ py: 1, pt: 2 }}
                  onClick={() => {
                    props.setOpenDrawer(false);
                    navigate(
                      "/products?category=" + encodeURIComponent("Irrigation")
                    );
                  }}
                />
                <TreeItem
                  nodeId="12"
                  label="Nutrient maker"
                  sx={{ py: 1 }}
                  onClick={() => {
                    props.setOpenDrawer(false);
                    navigate(
                      "/products?category=" + encodeURIComponent("Irrigation")
                    );
                  }}
                />
              </TreeItem>
              <TreeItem
                nodeId="2"
                label="Environmental Control"
                sx={{ py: 1 }}
                onClick={() => {
                  props.setOpenDrawer(false);
                  navigate("/products");
                }}
              />
              <TreeItem nodeId="3" label="Power Management" sx={{ py: 1 }}>
                <TreeItem
                  nodeId="31"
                  label="Solar Powered Refrigeration"
                  sx={{ py: 1 }}
                  onClick={() => {
                    props.setOpenDrawer(false);
                    navigate(
                      "/products?category=" +
                        encodeURIComponent("Power Management")
                    );
                  }}
                ></TreeItem>
              </TreeItem>
              <TreeItem
                nodeId="4"
                label="Crop Spraying"
                sx={{ py: 1 }}
                onClick={() => {
                  props.setOpenDrawer(false);
                  navigate("/products?category=" + encodeURIComponent("Crop Spraying"));
                }}
              ></TreeItem>
              <TreeItem
                nodeId="5"
                label="Crop Inspection"
                sx={{ py: 1 }}
                onClick={() => {
                  props.setOpenDrawer(false);
                  navigate("/products?category=" + encodeURIComponent("Crop Inspection"));
                }}
              ></TreeItem>
            </TreeItem>
            <TreeItem
              nodeId="about-us"
              label="About us"
              sx={{ py: 1 }}
              onClick={() => {
                props.setOpenDrawer(false);
                navigate("/about");
              }}
            ></TreeItem>
            <TreeItem
              nodeId="contact"
              label="Contact"
              sx={{ py: 1 }}
              onClick={() => {
                props.setOpenDrawer(false);
                navigate("/contact");
              }}
            ></TreeItem>
          </TreeView>
        </Grid>
        <Grid item xs={12} sx={{ textAlign: "center", py: 4, display: "none" }}>
          <LinkedInIcon sx={{ mx: 1 }} />
          <TwitterIcon sx={{ mx: 1 }} />
          <YouTubeIcon sx={{ mx: 1 }} />
        </Grid>
      </Grid>
    </Drawer>
  );
}
